<template>
  <v-row>
    <v-col>
      <v-progress-linear
        v-if="carregando"
        color="primary"
        indeterminate
      />
      <canvas
        id="grafico-passagens-por-ponto-de-captura"
        width="100%"
        height="170"
      />
    </v-col>
  </v-row>
</template>

<script>
  import Chart from 'chart.js'
  import perfilVeiculoApi from '@/api/perfil-veiculo'

  export default {

    props: {
      placa: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      carregando: false,
    }),

    mounted () {
      this.criar()
    },

    methods: {
      async criar () {
        this.carregando = true
        const resposta = await perfilVeiculoApi.pontosCaptura(this.placa)
        this.carregando = false

        const labels = resposta.data.length > 0
          ? resposta.data.map((item) => item.descricao)
          : ['']

        const dataset = resposta.data.length > 0
          ? resposta.data.map((item) => item.totalLeituras)
          : [0]

        const ctx = document.getElementById('grafico-passagens-por-ponto-de-captura')

        /* eslint-disable no-new */
        new Chart(ctx, {
          type: 'bar',
          data: {
            labels,
            datasets: [{
              data: dataset,
              backgroundColor: this.$vuetify.theme.themes.light.primary,
            }],
          },
          options: {
            title: {
              display: true,
              text: 'Passagens por ponto de captura',
            },
            legend: {
              display: false,
            },
            maintainAspectRatio: false,
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Passagens',
                },
                ticks: {
                  beginAtZero: true,
                  precision: 0,
                },
              }],
            },
          },
        })
      },

    },
  }
</script>
